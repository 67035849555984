.MuiCheckbox-root {

  &.checkbox-blue-light {
    color: #ccc;

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      @apply text-blue-light;
    }
  }
}

.MuiRadio-root:not(.Mui-checked) {
  color: #ccc;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background: #0B1C34;
  border-radius: 0;
  font-size: 0.75rem;
  max-width: 36rem;
  box-shadow: 0 2px 6px #0B1C3440;

  .MuiTooltip-arrow {
    color: #0B1C34;
  }
}

.MuiSwitch-root .MuiSwitch-switchBase {
  &:not(.Mui-checked) {
    color: #8694A5;

    &~.MuiSwitch-track {
      @apply bg-light;
      @apply opacity-100;
    }
  }
}

.MuiTabs-root {

  &.disabled {

    .MuiTabs-indicator {
      background: #ccc;
    }
  }

  .MuiTab-root {
    text-transform: initial;
    border-bottom: 1px solid #EBEBEB;

    &.Mui-selected {
      font-weight: bold;
    }
  }
}

.MuiAccordion-root {
  @apply px-4;
  @apply py-0;

  &.MuiAccordion-rounded {
    @apply shadow-md;
    @apply overflow-hidden;

    &:first-of-type {
      @apply rounded-tl-md;
      @apply rounded-tr-md;
    }

    &:last-of-type {
      @apply rounded-bl-md;
      @apply rounded-br-md;
    }
  }
}

.MuiSnackbar-root {

  .MuiSnackbarContent-root {
    background: #454545;
    @apply font-normal;
  }

  .MuiAlert-root {
    background: #454545 !important;

    .MuiAlert-icon {
      @apply flex items-center;

      svg {
        @apply w-8 h-8;
      }
    }

    .MuiAlert-message {
      @apply flex items-center font-medium;
    }

    .MuiAlert-action {
      @apply flex items-center pt-0 pl-10;

      .MuiIconButton-root {
        @apply w-6.5 h-6.5 bg-gray-75 text-gray-45 transition-all;

        &:hover {
          @apply text-white;
        }
      }
    }

    &.MuiAlert-filledSuccess {

      .MuiAlert-icon {
        @apply text-success;
      }
    }

    &.MuiAlert-filledError {

      .MuiAlert-icon {
        @apply text-primary;
      }
    }
  }
}

.MuiSlider-root {

  &.readonly {

    .MuiSlider-thumb {

      &::before {
        @apply shadow-none;
      }
    }
  }

  &.size-xs {

    .MuiSlider-thumb {
      @apply w-3 h-3;
    }
  }

  &.slider-primary {
    @apply text-primary;

    &.readonly {
      color: #F6A734 !important;
    }

    .MuiSlider-rail {
      @apply text-light;
      @apply opacity-100;
      height: 1.5px;
    }

    .MuiSlider-thumb {

      &:hover,
      &.Mui-focusVisible {
        box-shadow: 0 0 0 8px rgba(#F6A734, 0.16);
      }

      &.Mui-active {
        box-shadow: 0 0 0 14px rgba(#F6A734, 0.16);
      }
    }
  }

  &.slider-success {
    @apply text-success;

    &.readonly {
      color: #A3CE5E !important;
    }

    .MuiSlider-rail {
      @apply text-light;
      @apply opacity-100;
      height: 1.5px;
    }

    .MuiSlider-thumb {

      &:hover,
      &.Mui-focusVisible {
        box-shadow: 0 0 0 8px rgba(#A3CE5E, 0.16);
      }

      &.Mui-active {
        box-shadow: 0 0 0 14px rgba(#A3CE5E, 0.16);
      }
    }
  }
}

.MuiList-root {
  padding: 0 !important;

  .MuiMenuItem-root {
    @apply px-4;
    @apply py-2;
  }
}

.PhoneInput {

  &.country-input {

    .PhoneInputCountry {
      width: 100%;
      margin: 0;

      .PhoneInputCountryIcon {
        height: 1.5rem;
        background: transparent;
        margin-right: auto;
        box-shadow: none;
      }
    }

    .PhoneInputInput {
      display: none;
    }
  }
}

.wavesurfer {

  wave {
    height: 128px !important;
  }
}


//Validaions Table Tooltips
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.2s ease 1s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: darkred;
  font-size: 12px;
  color: #FFFFFF;
  position: absolute;
  padding: 1px 5px 2px 5px;
  white-space: nowrap;
  opacity: 0;
  bottom: 70%;
  left: 30%;
  z-index: 99999;
  visibility: hidden;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
}

[data-title] {
  position: relative;
}