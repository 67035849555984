.custom-select-dropdown .rdrDateRangePickerWrapper {

  .rdrDefinedRangesWrapper,
  .rdrMonthAndYearWrapper {
    display: none;
  }

  .rdrMonthName,
  .rdrWeekDay {
    text-align: center;
    color: #424242;
    font-weight: bold;
  }

  .rdrMonth {
    width: 16.5rem;
    padding: 0 1.5rem;
  }

  .rdrDayNumber {
    margin-bottom: 0.6em;

    span::after {
      display: none;
    }
  }

  .rdrInRange {
    opacity: 0.1;
    //border-radius: 0;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #1d2429;
  }

  .rdrDayToday span {
    font-weight: bold;
  }
}
