.sms-balloon {
  position: relative;
  padding: 1rem;
  border-radius: 1.5rem 1.5rem 1.5rem 0;

  &::before {
    position: absolute;
    left: -1rem;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: 1;
    content: '';
    display: block;
    margin-top: -1em;
    border-right: 1rem solid currentColor;
    border-top: 1rem solid transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff4;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff9;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fffd;
  }
}