.sidebar {
    /*z-index: 1000 !important;*/
}

@media(min-width: 768px) {
    .sidebar:hover .menu-panel {
        width: 11rem !important;
        padding-left: 0.75rem !important;
    }
}
