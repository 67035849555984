.data-table {

  .MuiTableHead-root .MuiTableCell-head {
    @apply text-blue-dark;
    @apply font-semibold;
    @apply whitespace-nowrap;
    @apply font-primary;
    @apply border-none;
  }

  .MuiTableBody-root .MuiTableCell-root {
    @apply whitespace-nowrap;
    @apply text-body;
    @apply font-primary;
    @apply border-none;
  }

  .MuiTableCell-root.MuiTableCell-paddingCheckbox {
    padding: 0 0.5rem !important;

    & ~ .MuiTableCell-root {
      padding-left: 0 !important;
    }
  }

  &.table-stripped {

    .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
      @apply bg-gray-f4;
    }
  }

  &.table-xs {

    .MuiTableHead-root .MuiTableCell-head {
      @apply text-sm;
      @apply px-4;
      @apply pt-4;
      @apply pb-3;
    }

    .MuiTableBody-root .MuiTableCell-root {
      @apply text-xs;
      @apply px-4;
      @apply py-1;
    }
  }

  .MuiTablePagination-root {
    @apply font-primary;
    @apply border-0;

    .MuiTablePagination-selectLabel,
    .MuiInputBase-root {
      @apply hidden;
    }

    .MuiTablePagination-displayedRows {
      @apply font-primary;
      @apply text-xs;
    }
  }
}
