.developer-documentation {

  .bg-opacity-mask {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff1a;
    }
  }

  .mask-shape1::before {
    clip-path: polygon(63% 0%, 100% 0%, 100% 100%, 57% 100%);
  }

  .mask-shape2::before {
    clip-path: polygon(31% 0%, 100% 0%, 100% 100%, 18% 100%);
  }
}
