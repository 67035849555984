@import url("~@fortawesome/fontawesome-free/css/all.min.css");
@import url("~react-date-range/dist/theme/default.css");
@import url("~react-date-range/dist/styles.css");
@import url("~react-phone-number-input/style.css");
@import url("/src/assets/scss/index.scss");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .has-tooltip:hover .tooltip {
      display: block;
      opacity: 1;
    }

    .has-hover-action:not(:hover) > .pnh-hidden {
      display: none !important;
    }

    .has-hover-action:not(:hover) > .pnh-transparent {
      opacity: 0 !important;
    }

    .clip-top-half {
      clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
    }

    .clip-bottom-half {
      clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
    }

    .table-header-hidden > table > thead,
    .table-body-hidden > table > tbody,
    .table-footer-hidden > table > tfoot {
      display: none;
    }

    .placeholder-font-normal::placeholder {
      font-weight: normal;
    }
  }
}
